<template>
  <v-snackbar v-model="snackbar" :timeout="notify.snackbar.timeout">
    {{ notify.snackbar.text }}
    <template v-slot:actions>
      <v-btn color="blue" variant="text" @click="setSnackbar({show:val, text:null})">
        Закрыть
      </v-btn>
    </template> 
  </v-snackbar>
</template>
<script> 
import { mapGetters, mapActions } from 'vuex' 
export default { 
  computed:{
    ...mapGetters("notify", { notify:"notify" }),
    snackbar:{
      get(){
        return this.notify.snackbar.show;
      },
      set(val){
        if(!val)
          this.setSnackbar({show:val, text:null})
      }
    }
  },
  methods:{
    ...mapActions("notify", ["setSnackbar"])
  }
}
</script>
 