export default {
	namespaced: true,
	state: {
		snackbar:{
			show: false,
			text: null,
			timeout: 2000,
		}, 
	},
	getters: { 
		notify:state=>{
			return state
		},  
	},
	mutations: { 
		setSnackbar(state, data){ 
			state.snackbar.show = data.show;
			state.snackbar.text = data.text;
		}, 
	},
	actions: {
		setSnackbar({ commit }, data){
			commit("setSnackbar", data)
		}
	}, 
}