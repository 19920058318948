import * as listsApi from '@/api/lists.js';

export default {
	namespaced: true,
	state: {
		object_type:[],
	},
	getters: { 
		list_object_type:state=>{
			return state
		},  
	},
	mutations: {
		setObjectType(state, object_type){ 
			state.object_type = object_type; 
		},  
		setName(state, data){ 
			state.object_type[data.key].name = data.e.target.value; 
		},
		setIconsrc(state, data){ 
			state.object_type[data.key].icon_src = data.e.target.value; 
		}
	},
	actions: {     
		async getObjectType({ commit }){
			await listsApi.getObjectType().then((response)=>{
				if(response.result)
					commit('setObjectType', response.object_type);	
			});
		},
		async createType({ dispatch }){
			await listsApi.createObjectType().then((response)=>{
				if(response.result)
					dispatch('getObjectType');	
			});
		}, 
		async upType({ state, dispatch }, key){
			await listsApi.upObjectType({
				id:state.object_type[key].id,
				name:state.object_type[key].name,
				icon_src:state.object_type[key].icon_src,
			}).then((response)=>{
				if(response.result){
					dispatch('notify/setSnackbar', {show:true, text:'Сохраненно'}, {root:true})
					dispatch('getObjectType');	
				}
			});
		},
		async deleteType({ state, dispatch }, key){
			await listsApi.deleteObjectType({
				id:state.object_type[key].id,
			}).then((response)=>{
				if(response.result)
					dispatch('getObjectType');	
				else
					dispatch('notify/setSnackbar', {show:true, text:'Тип уже используется в объектах'}, {root:true})
				
			});
		}, 
	}, 
}