import * as objectApi from '@/api/object.js';

export default {
	namespaced: true,
	state: {
		objects:[],
	},
	getters: { 
		object_all:state=>{
			return state
		},  
	},
	mutations: { 
		setObjects(state, objects){ 
			state.objects = objects; 
		},  
	},
	actions: {      
		async getObjects({ commit }, filter){
			await objectApi.getObjects(filter).then((response)=>{
				if(response.result)
					commit('setObjects', response.objects);	
			});
		},
		async createObject({ state }, category_id){
			console.log(state)
			let response = await objectApi.createObject(category_id);
			return response
		}, 
	}, 
}