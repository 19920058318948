import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios' 
import { loadFonts } from './plugins/webfontloader'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

loadFonts()

const app = createApp(App);
app.use(router)
app.use(store)
app.use(vuetify)
app.use(VueAxios, axios)
app.component('QuillEditor', QuillEditor)
app.mount('#app')
