import server from '@/api/http';

export async function login(params){  
	let { data } = await server.get('erp/login', { params });
	return data;
} 
export async function auth(){  
	let { data } = await server.get('erp/auth');
	return data;
}
export async function logout(params){  
	let { data } = await server.get('erp/logout', { params });
	return data;
}
export async function validToken(params){ 
	let { data } = await server.get('erp/valid/token', { params });
	return data;
}