<template> 
  <div class="admin-sidebar" id="sidebar">
    <v-navigation-drawer
      class="bg-deep-purple"
      theme="dark"
      permanent
    >
      <v-list>
        <v-list-item prepend-icon="mdi-rocket" title="Главная" :to="{name:'adminWelcome'}"></v-list-item>
        <v-list-item prepend-icon="mdi-map-marker-outline" title="Объекты" :to="{name:'objectAll'}"></v-list-item>
        <v-list-item prepend-icon="mdi-format-list-checkbox" title="Списки" :to="{name:'objectType'}"></v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logout()">
            Выйти
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main style="height: 400px"></v-main>
  </div>
</template>
<script> 
import { defineComponent } from 'vue';  
import { useStore } from 'vuex' 
import { useRouter } from 'vue-router'
import * as api from '@/api/account.js';

export default defineComponent({ 
  setup(){
    
    const $store = useStore();
    const router = useRouter()

    const logout = async ()=>{
      await api.logout({user_id:$store.state.account.id}).then((response)=>{ 
        if(response.result == true) { 
          localStorage.removeItem('token');   
          router.push({name:"pageIndex"});   
        }
      });   
    }

    return{
      logout
    }
  }
})
</script>
 