import { createStore } from 'vuex'
import account from './account';
import css from './css';
import notify from './notify';
import object_all from './objects/object_all';
import object_place from './objects/object_place';
import list_object_type from './lists/list_object_type';
import list_countries from './lists/list_countries';
 
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account,
    css,
    notify,
    object_all,
    object_place,
    list_object_type,
    list_countries
  },
  strict: process.env.NODE_ENV !== 'production'
})
