import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import middlewarePipeline from './middlewarePipeline'
import token from './middleware/token' 
import auth from './middleware/auth'
import guest from './middleware/guest' 
 
const routes = [
  {
    path: "/",
    name: "pageIndex",
    component: () => import('@/views/pages/pageIndex.vue'),
    meta: {  
      layout: 'page', 
      middleware: [
        token, guest
      ]  
    }, 
  },

  {
    path: "/admin",
    name: "adminIndex",
    component: () => import('@/views/admin/adminIndex.vue'),
    children:[
      {
        path: 'welcome',
        name: 'adminWelcome', 
        component: () => import('@/views/admin/adminWelcome.vue'), 
      },    
      {
        path: 'objects',
        name: 'objectAll', 
        component: () => import('@/views/admin/object/objectAll.vue'), 
      },
      {
        path: 'object_place_edit/:id',
        name: 'objectPlaceEdit', 
        component: () => import('@/views/admin/object/objectPlaceEdit.vue'), 
      }, 
      {
        path: 'lists',
        name: 'listsIndex', 
        component: () => import('@/views/admin/lists/listsIndex.vue'),
        children:[
          {
            path: 'object_type',
            name: 'objectType', 
            component: () => import('@/views/admin/lists/objectType.vue'), 
          }, 
          {
            path: 'list_country',
            name: 'listCountry', 
            component: () => import('@/views/admin/lists/listCountry.vue'), 
          }, 
        ] 
      },    
    ],
    meta: {  
      layout: 'erp', 
      middleware: [
        token, auth
      ]  
    }, 
  },   
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  
  if(!to.meta.middleware) return next()

  let params = to.params
  const middleware = to.meta.middleware
  const context = {
      to,
      from,
      next,
      store,
      params
  }
  return middleware[0]({
      ...context, 
      nextMiddleware: middlewarePipeline(context, middleware, 1)
  })

});

export default router
