import * as listsApi from '@/api/lists.js';

export default {
	namespaced: true,
	state: {
		countries:[],
		dialog_actions:{
			show:false,
			action:null,
			key_country:null,
			key_region:null,
			value:null,
		}
	},
	getters: { 
		list_countries:state=>{
			return state
		},  
	},
	mutations: {
		setСountries(state, countries){ 
			state.countries = countries; 
		},  
		showRegion(state, data){ 
			state.countries[data.key_country].regions[data.key_region].show = state.countries[data.key_country].regions[data.key_region].show ? false : true;
		},
		showDialogActions(state, data){ 
			state.dialog_actions.show = data.show;
			state.dialog_actions.action = data.action;
			state.dialog_actions.key_country = data.key_country;
			state.dialog_actions.key_region = data.key_region;

			if(data.action == "edit_region"){
				state.dialog_actions.value = state.countries[data.key_country].regions[data.key_region].name
			}
			else
				state.dialog_actions.value = null;

		},
		setDialogActionsValue(state, value){ 
			state.dialog_actions.value = value
		}
	},
	actions: {     
		async getСountries({ commit }){
			await listsApi.getСountries().then((response)=>{
				if(response.result)
					commit('setСountries', response.countries);	
			});
		},
		async createСountry({ state, commit, dispatch }){ 

			let country_id = null; 
			let action = state.dialog_actions.action;

			if(action == "add_region")
				country_id = state.countries[state.dialog_actions.key_country].id

			await listsApi.createСountry({
				action,
				value:state.dialog_actions.value, 
				country_id, 
			}).then((response)=>{
				if(response.result){
					dispatch('getСountries');	
					commit("showDialogActions", {
						show:false,
						action:null,
						key_country:null,
						key_region:null,
					})
				}
			});
		},
		async deleteСountry({ dispatch }, data){ 
			await listsApi.deleteСountry({
				type:data.type,
				id:data.id, 
			}).then((response)=>{
				if(response.result){
					dispatch('getСountries');	
				}
			});
		},
		async upСountry({ state, commit, dispatch }){ 

			let action = state.dialog_actions.action;
			let type = null;
			let id = null;

			if(action == "edit_region"){
				type = "region"
				id = state.countries[state.dialog_actions.key_country].regions[state.dialog_actions.key_region].id;
			}

			await listsApi.upСountry({
				id, 
				type,
				name:state.dialog_actions.value,
			}).then((response)=>{
				if(response.result){
					dispatch('getСountries');	
					commit("showDialogActions", {
						show:false,
						action:null,
						key_country:null,
						key_region:null,
					})
				}
			});
		}
	}, 
}