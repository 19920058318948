import server from '@/api/http';

export async function getObjects(params){  
	let { data } = await server.get('erp/object/get_all', { params });
	return data;
}
export async function getObject(params){  
	let { data } = await server.get('erp/object/get', { params });
	return data;
}  
export async function createObject(params){  
	let { data } = await server.get('erp/object/create', { params });
	return data;
} 
export async function upObject(params){  
	let { data } = await server.post('erp/object/up', params);
	return data;
} 
export async function deleteObject(params){  
	let { data } = await server.get('erp/object/delete', { params });
	return data;
} 
export async function checkUrlObject(params){  
	let { data } = await server.get('erp/object/check/url', { params });
	return data;
}
export async function publishObject(params){  
	let { data } = await server.get('erp/object/publish', { params });
	return data;
}
export async function loadImageObject(params){  
	let { data } = await server.post('erp/object/images/load', params);
	return data;
}
export async function getImagesObject(params){  
	let { data } = await server.get('erp/object/images/get', { params });
	return data;
}
export async function deleteImageObject(params){  
	let { data } = await server.get('erp/object/images/delete', { params });
	return data;
}
export async function createGptText(params){  
	let { data } = await server.get('erp/object/gpt/text/create', { params });
	return data;
}