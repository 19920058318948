import server from '@/api/http';

export async function getObjectType(params){  
	let { data } = await server.get('erp/lists/object_type/get', { params });
	return data;
}
export async function createObjectType(params){  
	let { data } = await server.get('erp/lists/object_type/create', { params });
	return data;
}
export async function upObjectType(params){  
	let { data } = await server.get('erp/lists/object_type/up', { params });
	return data;
}
export async function deleteObjectType(params){  
	let { data } = await server.get('erp/lists/object_type/delete', { params });
	return data;
}

//////

export async function getСountries(params){  
	let { data } = await server.get('erp/lists/countries/get', { params });
	return data;
}
export async function createСountry(params){  
	let { data } = await server.get('erp/lists/countries/create', { params });
	return data;
}
export async function upСountry(params){  
	let { data } = await server.get('erp/lists/countries/up', { params });
	return data;
}
export async function deleteСountry(params){  
	let { data } = await server.get('erp/lists/countries/delete', { params });
	return data;
}
