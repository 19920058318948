<template>
  <v-app>
    <div class="layout-erp"> 
      <admin-sidebar></admin-sidebar> 
      <div class="admin-content" :style="styleContent">
        <router-view></router-view>
      </div>
    </div>  
    <notify-snackbar></notify-snackbar>
  </v-app>  
</template>
<script>
import { mapGetters } from 'vuex' 
import AdminSidebar from "@/components/sidebar/admin_sidebar.vue"
import NotifySnackbar from '@/components/notify_snackbar.vue'
export default { 
  data () {
    return {}
  },
  computed:{
    ...mapGetters("css", { css:"css" }),
    styleContent(){
      return { width:(this.css.body.width - this.css.sidebar.width)+"px"}
    }
  },
  mounted(){ 
  }, 
  methods:{  
  },
  components:{
    AdminSidebar,
    NotifySnackbar,
  }
}
</script>