<template>
  <component :is="layout">  
    <router-view></router-view> 
  </component>
</template>

<script>
import { markRaw } from "vue";
import layout_page from '@/layouts/layout_page.vue'
import layout_erp from '@/layouts/layout_erp.vue' 
import { mapActions } from 'vuex' 

export default { 
  created() {
    window.addEventListener("resize", this.init);
  },
  computed: { 
    layout(){  
        let layout = layout_page;
        if(this.$route.meta.layout == "page") layout = layout_page;
        if(this.$route.meta.layout == "erp") layout = layout_erp;    
        return markRaw(layout)
    }
  },
  mounted(){
    window.setTimeout(() => {
      this.init();
    },600) 
  }, 
  methods:{ 
    ...mapActions("css", ["init"]), 
  }, 
}
</script>
