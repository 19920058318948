import * as accountApi from '@/api/account.js';

export default {
	namespaced: true,
	state: {
		token:null,
		id:null,
		profile:null, 
	},
	getters: {
		isToken: state =>{
			return state.token ? true : false;
		},		
		account:state=>{
			return state
		},  
	},
	mutations: {
		setToken(state, token){ 
			state.token = token; 
		}, 
		setAccount(state, data){  
			state.id = data.id
			state.profile = data.profile
		},  
	},
	actions: {    
		async loadToken({ commit }){

			let token = localStorage.getItem('token') ? localStorage.getItem('token') : false; 

			if(token){ 
				let data = await accountApi.validToken({ token });	 
				if(!data.result) token = false; 
			} 
			
			commit('setToken', token);	

			return token;
		},
		async loadAccount({ commit }){			 
			
			try { 
				let data = await accountApi.auth(); 	  
				commit('setAccount', data);	
			} catch (error) {
				localStorage.removeItem('token')
				commit('setToken', false);	
			}  			 
		}, 
	}, 
}