export default {
	namespaced: true,
	state: {
		body:{
			height:null,
			width:null
		}, 
		sidebar:{
			width:null, 
		}
	},
	getters: { 
		css:state=>{
			return state
		},  
	},
	mutations: { 
		setPageSidebar(state, data){ 
			state.sidebar.width = data.width;
		}, 
		setBody(state, data){ 
			state.body.height = data.height;
			state.body.width = data.width;
		}
	},
	actions: {
		init({ commit }){
			if(document.querySelector('body') !== null){
				let body = getComputedStyle(document.querySelector('body'));
				commit("setBody", { height:parseFloat(body.height), width:parseFloat(body.width)})
			}
			if(document.getElementById('sidebar') !== null){
				let sidebar = getComputedStyle(document.getElementById("sidebar")); 
				commit("setPageSidebar", { width:parseFloat(sidebar.width) }) 
			}
		},
	}, 
}